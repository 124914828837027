import { FC, useState, useMemo } from "react";

import { EditableDescription, Pill, SectionHeading, useToast, Select, Column, Row, SearchInput, Text } from "@hightouchio/ui";
import * as Sentry from "@sentry/react";

import { useDraft } from "src/contexts/draft-context";
import { ModelColumnsOrderBy, useUpdateModelColumnDescriptionMutation, useUpdateModelColumnMutation } from "src/graphql";
import { ColumnType } from "src/types/visual";
import { Table, TableColumn } from "src/ui/table";

type Props = {
  columns: any;
  loading: boolean;
  modelId: string | undefined;
  orderBy: ModelColumnsOrderBy | undefined;
  onSort(sortKey: string): void;
  isDraft: boolean;
  dbtColumns?: { name: string; description?: string }[];
};

const getTypeOptions = (warehouseType) => {
  const typeOptions = [
    {
      value: ColumnType.Boolean,
      label: "Boolean",
    },
    {
      value: ColumnType.Number,
      label: "Number",
    },
    {
      value: ColumnType.String,
      label: "String",
    },
    {
      value: ColumnType.Timestamp,
      label: "Timestamp",
    },
    {
      value: ColumnType.Date,
      label: "Date",
    },
    {
      value: ColumnType.Json,
      label: "Object / Array",
    },
  ];
  return typeOptions.map((option) => {
    if (option.value === warehouseType) {
      return {
        ...option,
        label: `${option.label} (Default)`,
      };
    }
    return option;
  });
};

export const ColumnSettings: FC<Readonly<Props>> = ({ columns, loading, modelId, orderBy, onSort, isDraft, dbtColumns }) => {
  const [search, setSearch] = useState<string>("");
  const { updateResourceOrDraft, draft, setSubmitDraftModalOpen } = useDraft();
  const { toast } = useToast();

  const updateModelColumnDescriptionMutation = useUpdateModelColumnDescriptionMutation();

  const tableColumns: TableColumn[] = useMemo(
    () => [
      {
        key: "name",
        name: "Name",
        max: "1fr",
        sortDirection: orderBy?.name,
        onClick: () => onSort("name"),
        cell: (name) => (
          <Text isTruncated fontWeight="medium">
            {name}
          </Text>
        ),
      },
      {
        name: "Type",
        sortDirection: orderBy?.type,
        onClick: () => onSort("type"),
        max: "250px",
        cell: ({ name, type, custom_type }) => {
          const [value, setValue] = useState(custom_type || type);
          const updateColumnMutation = useUpdateModelColumnMutation();
          return (
            <Select
              width="3xs"
              options={getTypeOptions(type)}
              value={value}
              isLoading={updateColumnMutation.isLoading}
              onChange={async (option) => {
                if (!modelId || !option) {
                  return;
                }
                const oldValue = value;
                setValue(option);

                const column = columns.find((column) => column.name === name);
                const newColumn = {
                  ...column,
                  custom_type: option,
                };
                const newColumns = columns.map((column) => {
                  if (column.name === name) {
                    return newColumn;
                  }
                  return column;
                });

                try {
                  if (updateResourceOrDraft) {
                    await updateResourceOrDraft(
                      {
                        _set: draft?.new_resource?._set || {},
                        modelColumns: newColumns,
                      },
                      () => undefined,
                      async () => {
                        await updateColumnMutation.mutateAsync({ id: modelId, name, input: { custom_type: option } });
                      },
                      isDraft,
                    );
                    toast({
                      id: "update-column",
                      title: "Column type updated",
                      variant: "success",
                    });
                  }
                } catch (err) {
                  Sentry.captureException(err);
                  toast({
                    id: "update-column",
                    title: "Column type failed to update",
                    variant: "error",
                  });
                  setValue(oldValue);
                }
                setSubmitDraftModalOpen(true);
              }}
            />
          );
        },
      },
      {
        name: "Description",
        max: "450px",
        cell: ({ name, description: initialDescription }) => {
          const [description, setDescription] = useState<string>(initialDescription || "");

          return (
            <Row marginY={4}>
              <EditableDescription
                value={description}
                width="md"
                onChange={(option) => setDescription(option)}
                externalValueSource="dbt"
                externalValue={dbtColumns?.find((column) => column.name?.toLowerCase() === name?.toLowerCase())?.description}
                onSubmit={async (option) => {
                  const oldDescription = description;
                  if (modelId) {
                    setDescription(option);
                    try {
                      await updateModelColumnDescriptionMutation.mutateAsync({ model_id: modelId, name, description: option });
                      toast({
                        id: "update-column-desc",
                        title: "Column description updated",
                        variant: "success",
                      });
                    } catch (err) {
                      Sentry.captureException(err);
                      toast({
                        id: "update-column-desc",
                        title: "Column description failed to update",
                        variant: "error",
                      });
                      setDescription(oldDescription);
                    }
                  }
                }}
              />
            </Row>
          );
        },
      },
    ],
    [],
  );

  const filteredColumns = columns.filter(({ name }) => name.toLowerCase().includes(search.toLowerCase()));

  return (
    <Column overflow="hidden">
      <Row alignItems="center" mb={4} justifyContent="space-between">
        <Row alignItems="center">
          <SectionHeading mr={2}>Columns</SectionHeading>
          <Pill>{columns?.length}</Pill>
        </Row>
        <SearchInput placeholder="Search columns..." value={search} onChange={(evt) => setSearch(evt.target.value)} />
      </Row>
      <Table
        columns={tableColumns}
        data={filteredColumns}
        disabled={({ disable }: { disable: boolean }) => disable}
        loading={loading}
        rowHeight="auto"
      />
    </Column>
  );
};
